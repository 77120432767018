import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import './Layout.css';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <div className="o-wrapper">{children}</div>}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
